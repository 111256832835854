import React from 'react'
import ihack_img from '../images/ihack.png'
import nas_img from '../images/nas.png'

const blogpost_list = [
    {
        name: 'iHack G4',
        id: 0,
        desc: 'You can teach an old dome new tricks',
        date: 'April 2, 2020',
        image: ihack_img,
        page: '/blog/2020-04-02-ihack',
        sample: (
            <p>
                These sorts of projects are all over, from this{' '}
                <a href="https://www.reddit.com/r/DIY/comments/4f35h6/">
                    Raspberry Pi Super Nintendo
                </a>{' '}
                to a{' '}
                <a href="https://www.reddit.com/r/DIY/comments/7wf5cb/">
                    Modern System in a 386 case
                </a>{' '}
                to a{' '}
                <a href="https://www.reddit.com/r/sleeperbattlestations/comments/f64ijl/">
                    Ryzen in a TRS80
                </a>{' '}
                to perhaps my personal favorite, this{' '}
                <a href="https://imgur.com/gallery/bxUlq">
                    PC in an old Philco radio
                </a>
                ; giving new life to an enclosure designed decades ago has a
                certain magic to it for many, myself included. I stumbled upon
                the chassis for this build while on Craigslist scavenging for
                parts for <a href="/blog/2019-09-23-nas">another build</a>, and
                at $30 I figured that even if I just used it as a display or
                stuffed a Raspberry Pi into it for a low powered linux machine I
                could get some use out of it (in retrospect this is a bad way to
                justify purchases). Although it&#39;s from this century, the
                internals make it ancient in computer terms, and the design is
                striking: unlike anything Apple had done before, with its flat
                panel screen and hemispherical base, and visually revolutionary
                in a way that few of their devices have been since. . .
            </p>
        )
    },
    {
        name: 'Bookcase NAS',
        id: 1,
        desc: 'Put your data on the shelf',
        date: 'September 23, 2019',
        image: nas_img,
        page: '/blog/2019-09-23-nas',
        sample: (
            <div>
                <p>
                    Some might call me a data hoarder. I have tons of documents,
                    project files, music, recorded audio and video, and, perhaps
                    most noteworthy, many terabytes of RAW photos. I try to
                    limit my usage by deleting bad or near-duplicate photos when
                    I import as well as regularly use{' '}
                    <a href="https://dev.yorhel.nl/ncdu">ncdu</a> or something
                    like <a href="https://windirstat.net/">WinDirStat</a> (for
                    Windows) to see what's taking up disk space and remove if
                    necessary.
                </p>
                <p>It's still bad. . .</p>
            </div>
        )
    }
]

export default blogpost_list
