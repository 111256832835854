import React from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import blog_posts from '../../data/blog_data'

const PostDescription = ({ title, desc, date, image, sample, url }) => (
    <div className="post-description blog-post-container">
        <Link to={url}>
            <h2>{title}</h2>
            <h3>{date}</h3>
            <p className="desc-text">{desc}</p>
            <div className="blog-post-content">
                <p>
                    <img className="post-desc-img" src={image} alt={title} />
                    {sample}
                </p>
                <p className="read-more">Read More</p>
            </div>
        </Link>
    </div>
)

const BlogPage = () => (
    <Layout>
        <SEO title="Blog" />
        <h1>Tom's Blog</h1>
        <p>Here you'll find projects, opinions, and articles.</p>
        {blog_posts &&
            blog_posts.map(post => (
                <PostDescription
                    title={post.name}
                    date={post.date}
                    desc={post.desc}
                    image={post.image}
                    sample={post.sample}
                    key={post.id}
                    url={post.page}
                />
            ))}
    </Layout>
)

export default BlogPage
